.plan {
  display: flex;
  border-radius: 0.8rem;
  color: var(--color-white);
  padding: 3.4rem 12rem;
  width: 100%;
  gap: 3.6rem;
  cursor: pointer;
  flex: 1, 0;
  @include desktop {
    padding: 1.5rem;
  }
  @include tablet{
    padding: 1.5rem
  }

 

  &_filter {
    
    display: flex;
    flex-direction: column;
    position: relative;
    width: 273px;
  max-height: 450px;
  ;
  
    background: #fff;
    box-shadow: 0px 2px 24px 4px rgba(0, 0, 0, 0.1);
    padding: 2.4rem;
    gap: 2.8rem;
    flex-shrink: 0;
    &_user {
      display: flex;
      padding: 4px 0px 8px 0px;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
      align-self: stretch;
      font-size: 1.6rem;
      border-bottom: 1px solid var(--Color-gray-50, #e6e6e6);
    }
    &_sumassured {
      div {
        color: var(--Color-gray-600, #4d4d4d);
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
      }
      input {
        border: none;
        width: 100%;
        border-bottom: 1px solid var(--Color-gray-50, #e6e6e6);
        padding: 1.2rem 1.6rem;
      }
    }
    &_filter_term {
      div {
        color: var(--Color-gray-600, #4d4d4d);
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
      }
      select {
        border: none;
        width: 100%;
        border-bottom: 1px solid var(--Color-gray-50, #e6e6e6);
        padding: 1.2rem 1.6rem;
      }
    }
  }
  &_content {
    width: 100%;
    &_searchresult {
      color: var(--Color-gray-600, #4d4d4d);
      font-family: Inter;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 300;
      line-height: 2.4rem; 
      margin-bottom: 2.4rem;
    }
  }
}
