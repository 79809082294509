.plandetails {
  max-width: 60rem; /* 600px / 10px */
  position: fixed;
  top: 0;
  z-index: 100;
  right: 0;
  background-color: #ffffff;
  height: 100vh;
  overflow: auto; 
  display: flex;
  flex-direction: column;
  gap: 2.4rem; 
  @include mobile{
    gap:1rem;
  }
  
  &_header {
    display: flex;
    max-width: 59.9rem; /* 599px / 10px */
    padding: 1.6rem 1.6rem; /* 8px / 10px, 16px / 10px */
    align-items: flex-start;
    gap: 15.9rem; /* 159px / 10px */
    background: var(--Color-cyan-0, #eaf7f5);
    position: sticky;
    top:0;
    right: 0;
    @include mobile{
      gap:1rem;
    }
    .insurancecompany {
      margin: 0 auto;
      display: flex;
      align-items: center;
      gap: 1.2rem; /* 12px / 10px */
      flex: 1 0 0;
      &_Logo{
        height: 90px;
        width: 90px;
      }
      @include mobile{
       flex-direction: column;
      }
      .companyinformation {
        margin: 0 auto;
        width: 27.2rem; /* 272px / 10px */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem; /* 4px / 10px */
        @include mobile{
          width:100%;
          margin: 0 auto;
        }
        .header {
          color: #000;
          font-family: Inter;
          font-size: 1.6rem; /* 16px / 10px */
          font-style: normal;
          font-weight: 700;
          line-height: 2.4rem; /* 24px / 10px */
         

        }
        .insurancename {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 1.6rem; /* 16px / 10px */
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem; /* 24px / 10px */
         
        }
        .ADB {
          color: var(--Color-gray-600, #4d4d4d);
          font-family: Inter;
          font-size: 1.4rem; /* 14px / 10px */
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .cross {
      button {
        background: url("./../../assets/cross.svg");
        width: 2.4rem; /* 24px / 10px */
        height: 2.4rem; /* 24px / 10px */
        border: none;
        cursor: pointer;
      }
    }
  }
  &_information {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.8rem; /* 28px / 10px */
    .insuranceinfo {
      display: inline-flex;
      padding: 0.8rem 1.6rem; /* 8px / 10px, 16px / 10px */
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem; /* 16px / 10px */
      width: 100%;
      h4 {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1.6rem; /* 16px / 10px */
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 24px / 10px */
      }
      p {
        color: var(--Color-gray-600, #4d4d4d);
        max-width: 53.5rem; /* 535px / 10px */
        font-family: Inter;
        font-size: 1.6rem; /* 16px / 10px */
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 24px / 10px */
        text-wrap: wrap;
      }
    }
    .benefits {
      margin: 0 auto;
      width: 100%;
      display: flex;
      max-width: 56.7rem; /* 567px / 10px */
      padding: 0.8rem 1.6rem; /* 8px / 10px, 16px / 10px */
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem; /* 16px / 10px */
      background: var(--Color-cyan-0, #eaf7f5);
      p{
        font-size: 1.6rem;
      }
      h4 {
        color: #000;
        font-family: Inter;
        font-size: 1.6rem; /* 16px / 10px */
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 24px / 10px */
      }
      ul {
        li {
          display: flex;
          // justify-content: flex-end;
          align-items: flex-start;
          gap: 0.8rem; /* 8px / 10px */
          align-self: stretch;
          list-style: none;
          div {
            color: var(--Color-gray-600, #4d4d4d);
            font-family: Inter;
            font-size: 1.6rem; /* 16px / 10px */
            font-style: normal;
            font-weight: 400;
            line-height: 2.4rem; /* 24px / 10px */
            text-wrap: wrap;
          }
        }
      }
    }
    .policyterm {
      margin: 0 auto;
      width: 100%;
      figure {
        max-width: 100%;
        table {
          tbody {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.2rem; /* 12px / 10px */
            align-self: stretch;
            border-left: 0.1rem solid var(--Color-gray-50, #e6e6e6); /* 1px / 10px */
            border-right: 0.1rem solid var(--Color-gray-50, #e6e6e6); /* 1px / 10px */
            border-radius: 0.8rem; /* 8px / 10px */
            tr {
              display: flex;
              padding: 0.8rem 1.6rem; /* 8px / 10px, 16px / 10px */
              align-items: center;
              gap: 3.6rem; /* 36px / 10px */
              align-self: stretch;
              border-bottom: 0.1rem solid var(--Color-gray-50, #e6e6e6); /* 1px / 10px */
              td {
                color: #000;
                font-family: Inter;
                font-size: 1.6rem; /* 16px / 10px */
                font-style: normal;
                font-weight: 400;
                line-height: 2.4rem; /* 24
                  td {
                  color: #000;
                  font-family: Inter;
                  font-size: 1.6rem; /* 16px / 10px */
                font-style: normal;
                font-weight: 400;
                line-height: 2.4rem; /* 24px / 10px */
                width: 100%;
              }
            }
            tbody tr:first-child {
              /* Your styles for the first row of tbody */
              border-radius: 0.8rem 0.8rem 0 0; /* 8px / 10px */
              background: var(--Color-cyan-800, #2cab93);
              td {
                color: var(--Color-gray-0, #fff);
                font-family: Inter;
                font-size: 1.6rem; /* 16px / 10px */
                font-style: normal;
                font-weight: 700;
                line-height: 2.4rem; /* 24px / 10px */
              }
            }
          }
        }
      }
    }
  }
  .entrydate {
    margin: 0 auto;
    width: 100%;
    display: flex;
    max-width: 53.2rem; /* 532px / 10px */
    padding: 0.8rem 1.6rem; /* 8px / 10px, 16px / 10px */
    align-items: flex-start;
    gap: 6.6rem; /* 66px / 10px */
    border-radius: 0.8rem; /* 8px / 10px */
    background: #fe9900;
    @include mobile{
      gap:1rem;
      flex-wrap: wrap;
    }
    p {
      color: var(--Color-gray-0, #fff);
      text-align: center;
      font-family: Inter;
      font-size: 1.6rem; /* 16px / 10px */
      font-style: normal;
      font-weight: 700;
      line-height: 2.4rem; /* 24px / 10px */
    }
  }
  .features {
    margin: 0 auto;
    width: 100%;
    figure {
      max-width: 100%;
      table {
        tbody {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.2rem; /* 12px / 10px */
          align-self: stretch;
          border-left: 0.1rem solid var(--Color-gray-50, #e6e6e6); /* 1px / 10px */
          border-right: 0.1rem solid var(--Color-gray-50, #e6e6e6); /* 1px / 10px */
          border-radius: 0.8rem; /* 8px / 10px */
          tr {
            display: flex;
            padding: 0.8rem 1.6rem; /* 8px / 10px, 16px / 10px */
            align-items: center;
            gap: 3.6rem; /* 36px / 10px */
            align-self: stretch;
            border-bottom: 0.1rem solid var(--Color-gray-50, #e6e6e6); /* 1px / 10px */
            td {
              color: #000;
              font-family: Inter;
              font-size: 1.6rem; /* 16px / 10px */
              font-style: normal;
              font-weight: 400;
              line-height: 2.4rem; /* 24px / 10px */
              width: 100%;
            }
          }
        }
        tbody tr:first-child {
          /* Your styles for the first row of tbody */
          border-radius: 0.8rem 0.8rem 0 0; /* 8px / 10px */
          background: var(--Color-cyan-800, #2cab93);
          td {
            color: var(--Color-gray-0, #fff);
            font-family: Inter;
            font-size: 1.6rem; /* 16px / 10px */
            font-style: normal;
            font-weight: 700;
            line-height: 2.4rem; /* 24px / 10px */
          }
        }
      }
    }
  }
}
