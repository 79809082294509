.Definition {
  margin-top: 1.6rem;
  width: 100vw;
  &_Content{
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    gap: 5rem;
    padding: 0px 12rem;
    @include maxWidth{
      padding: 0 2rem;
      gap:2rem;
    }
    @include desktop{
      padding: 0 1rem;
      gap: 1rem;
    }
    @include tablet{
      padding: 0 .5rem;
    }
  }
 
  &_Left {
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &_Top {
      height: auto;
      background: $secondary-dark-color;
      @include flex-container(row, nowrap, center, center);
      &_Left {
        flex: 1;
        padding: 2rem 3.2rem;

        height: 100%;
        @include flex-container(column, nowrap, center);
        gap: 1.2rem;
        h1 {
          @include set-font-line-height(1.6rem);
          font-weight: 700;
        }
        p {
          @include set-font-line-height(1.6rem);
          font-weight: 400;
        }
      }
      &_Right{
        @include mobile{
          display: none;
        }
      }
    }
    &_Bottom {
    flex:1;
      margin-top: 1.2rem;
      padding: 3.2rem;
      background: #f6f6f6;
      @include flex-container(column, nowrap,center ,flex-start );
      gap:1.2rem;
      h1{
        @include set-font-line-height(2.4rem);
        font-weight: 700;
        span{
            color: var(--Color-cyan-800, #2CAB93);
        }
      }
      p{
        @include set-font-line-height(1.6rem);
        color: var(--Color-gray-600, #4D4D4D);
      }
    }
  }
  &_Right {
    position: relative;
    @include desktop{
      display: none;
    }
    &_Bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      background: $info-light-color;
      height: 10.1rem;
      width: 100%;
      @include flex-container(column, nowrap, center, center);
      @include maxWidth{
        height: 9.1rem;
      }
      &_Heading {
        @include set-font-line-height(2.4rem);
        color: $white-color-1;
        margin-bottom: 0.8rem;
      }
      &_Text {
        display: flex;
        align-items: center;
        &_Flex {
          display: flex;
          align-items: center;
          &:first-child {
            border-right: 1px solid $white-color-1;
            padding-right: 2.5rem;
          }
          &:last-child {
            padding-left: 2.5rem;
          }
          p {
            @include set-font-line-height(1.6rem);
            color: $white-color-1;
          }
        }
      }
      &_Icon {
        color: $white-color-1;
        height: 2.4rem;
        width: 2.4rem;
        margin-right: 0.7rem;
      }
    }
  }
}
