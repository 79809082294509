.product {
  &_banner {
    // width: 1440px;
    // height: 410px;
    flex-shrink: 0;
    width: 100vw;
    height: 40.6rem;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url("./../../assets/productback.png") lightgray 50% / cover no-repeat;
    background-repeat: no-repeat; /* Ensures the background image does not repeat */
    background-size: cover; /* This scales the background image to cover the entire element */
    background-position: center;
  }
  &_section {
    position: relative;
    transform: translateY(-34.6rem);
    max-width: $max-width;
    margin:0 auto;
    &_title {
      color: var(--Color-gray-0, #fff);
      text-align: center;
      font-family: Inter;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem;
      margin-bottom: 0.8rem;
    }
    &_desc {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem;
      margin-bottom: 7.2rem;
    }
    &_list {
      padding: 0rem 12rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 3.2rem;
    }
  }
}

.productcard {
  width: 37.6rem;
  height: 42.6rem;
  display: flex;
  margin-bottom: 3.6rem;
  flex-direction: column;
  cursor: pointer;

  &_fixedimage {
    background-color: #fff;
    border-top: 3px solid var(--Color-cyan-800, #2cab93);
    position: fixed;
    transform: translateY(-8rem);
    padding: 1rem;
  }

  &_image {
    width: 100%;
    height: 100%;
  }
  &_content {
    display: flex;
    padding: 3.2rem 1.6rem 1.6rem 1.6rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
    background: #f6f6f6;
    transition: background-color 0.4s ease-in-out 0.2s;

    &_header {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem;
      transition: color 0.4s ease-in-out 0.2s;
    }
    &_desc {
      color: var(--Color-gray-600, #4d4d4d);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 2.4rem;
      text-wrap: wrap;
      align-self: stretch;
      transition: color 0.4s ease-in-out 0.2s;
    }
    &_link {
      display: flex;
      padding: 1.2rem 2.4rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      border: none;
      border-radius: 1rem;
      background: inherit;
      transition: background-color 0.4s ease-in-out 0.2s,
        color 0.4s ease-in-out 0.2s;
      font-weight: 500;
      font-size: 1.6rem;
      font-style: normal;
      line-height: 2.4rem;
    }
  }
  &:hover {
    .productcard_content {
      background: #2cab93;
    }
    .productcard_content_link {
      background: #fff;
      color: #2cab93;
    }

    .productcard_content_header {
      color: #fff;
    }
    .productcard_content_desc {
      color: #fff;
    }
    .productcard_viewall {
      background: #2cab93;
    }
    .productcard_viewall_header {
      color: #fff;
    }
    .productcard_viewall_desc {
      color: #fff;
    }
  }
  &_viewall {
    display: flex;
    flex-direction: column;
    gap: 1.6px;
    width: 37.6rem;
    height: 11.7rem;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    transition: background-color 0.4s ease-in-out 0.2s;
    &_header {
      color: var(--Color-gray-900, #000);
      text-align: center;
      font-family: Inter;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem;
      border: none;
      background: inherit;
      margin-top: 2.75rem;
      transition: color 0.4s ease-in-out 0.2s;
    }
    &_desc {
      color: var(--Color-gray-600, #4d4d4d);
      font-family: Inter;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.4rem;
      margin-bottom: 3.6rem;
      transition: color 0.4s ease-in-out 0.2s;
    }
  }
}
