.FAQ {
  @include flex-container(column, nowrap, center, center);
  width: 100%;
  padding: 6rem 12rem;

  gap: 2.4rem;
  background: var(--Color-cyan-0, #eaf7f5);
  @include maxWidth {
    padding: 4rem;
}
  @include tablet {
    padding: 3rem 1rem;
  }
  h1 {
    position: relative;
    display: inline-block;
    span {
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 50%; // The underline covers 50% of the text
      height: 4px; // Thickness of the underline
      background: $primary-dark-color; // Color of the underline
    }
  }
  &_data {
    @include flex-container(column);
    max-width: 99.4rem;
    width: 100%;
    gap: 1.6rem;
    height: auto;
    // background: red;
    margin: 0 auto;
    &_item {
      border-top: 0.5px solid var(--Color-gray-50, #e6e6e6);
      border-bottom: 0.5px solid var(--Color-gray-50, #e6e6e6);
      padding: 2.4rem;
      span {
        color: #767676;
        border-left: 0.2rem solid var(--Color-cyan-800, #2cab93);
        margin-top: 1.5rem;
        @include set-font-line-height(1.6rem);
        display: flex;
        padding: 0 2.4rem;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        gap: 1rem;
        align-self: stretch;
        font-weight: 400;
        p {
          font-weight: 400;
        }
      }
      &_content {
        display: flex;
        width: 100%;

        align-items: center;
        gap: 3.2rem;
      }

      &_icon {
        height: 2.4rem;
        width: 2.4rem;
      }
      p {
        @include set-font-line-height(1.6rem);
        font-weight: 700;
      }
    }
  }
}
