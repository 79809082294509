.Navbar {
  width: 100vw;
 

  height: auto;
  background: $white-color-1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  left: 0;
  // overflow: hidden;
  
  &_Content {
    max-width: $max-width;
    padding: 2rem 12rem;

    @include desktop {
      padding: 1.5rem;
    }
    @include tablet {
      padding: 1.5rem;
    }
    @include mobile {
      display: flex;
      padding: 1.2rem 2.4rem;
      justify-content: space-between;
      align-items: center;
    }
    display: flex;
    // padding: 12px 24px;

    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @include flex-container(row, wrap, space-between, center);
  }
  &_HamBurger {
    display: none;
    &_Image {
      display: none;
      @include mobile {
        display: block;
      }
    }
    @include mobile {
      display: block;
    }
  }
  &_Logo {
    // background: red;
    // @include desktop {
    //   width: 5rem;
    // }
    // @include tablet {
    //   padding: 0.5rem 1.2rem;
    // }
    &:hover {
      cursor: pointer;
    }
    &_Image {
      width: 24rem;
      height: auto;
      // @include desktop {
      //   width: 20rem;
      // }
      // @include tablet {
      //   width: 16rem;
      // }
    }
  }
  &_Auto {
    height: auto;
    width: auto;
    @include mobile {
      display: none;
    }
  }
  &_Hundred {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    &_Hello {
      flex: 1;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  &_Right {
    gap: 5.6rem;
    @include desktop {
      gap: 3rem;
    }
    @include tablet {
      gap: 2rem;
    }
    @include mobile {
      display: none;
    }

    @include flex-container(row, wrap, space-between, center);
    &_Box {
      border-bottom: 1px solid var(--Color-gray-50, #e6e6e6);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // background: red;
      // padding-left:2rem;
      // padding-right: 2rem;
      padding: 2rem;
      // padding-bottom: 2rem;
    }
    &_Link {
      color: #000;
      @include set-font-line-height(1.6rem);
      font-style: normal;
      text-decoration: none;
      font-weight: 400;
      display: flex;
      align-items: center;
      position: relative;
      p{
        margin: 0;
      }
      &_Dropdown{
        position: absolute;
        left:0;
        top:4rem;
        height: auto;
        background: white;
        // background:red;
        gap: 1.2rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        z-index:120;
        display: flex;
        flex-direction: column;
        min-width:20rem;
        width:auto;
        padding: 1.2rem 2rem;
        border-radius: .5rem;
        color: #000;
        a{
          text-decoration: none;
          color:#000;
        }


      }
      &:hover {
        cursor: pointer;
      }
      @include tablet {
        @include set-font-line-height(1.4rem);
      }
      @include mobile {
        @include set-font-line-height(16px);
        // margin: 2rem;
        margin: 0 2rem;
      }
    }
  }
}
@include mobile {
  .Navbar_Right.open {
    display: flex; /* Show links when open */
    flex-direction: column; /* Stack links vertically */
    position: fixed;
    z-index: 100;
    min-width: 323px;
    width: 70vw;
    height: 100%;
    top: 0; /* Below the navbar */
    right: 0;
    background-color: #fff;
    // padding: 10px;
    border-radius: 5px;
    display: inline-flex;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // gap: 16px;
    flex-shrink: 0;
    &_Link {
      color: #000;

      /* Body/regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
