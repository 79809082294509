.Works {
  @include flex-container(column, nowrap, center, center);
  gap: 3.2rem;
  max-width: 120rem;
  width: 100%;

  padding: 6rem;
  margin: 0 auto;
  border-radius: 1.6rem 1.6rem 0px 0px;
  background: #fff;
  box-shadow: 0px -0.2rem 1.6rem 0px rgba(0, 0, 0, 0.08);
  margin-top: -5rem;
  @include desktop {
    padding: 3rem;
  }
  h1 {
    color: #000;
    text-align: center;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      font-weight: 700;
    }
  }
  p {
    color: var(--Color-gray-600, #4d4d4d);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &_Data {
    @include flex-container(row, wrap, flex-start, center);
    padding-left: 3rem;
    row-gap: 2.6rem; /* Gap between rows */
    // column-gap: 14.8rem;
    width: auto;
    margin: 0 auto;
    @include desktop {
      // padding: 0;
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }
}

.WorkSingle {
  padding: 1rem 1.6rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Color-gray-50, #e6e6e6);
  background: #fff;
  max-width: 45rem;

  // width: 41%;

  margin-left: 4rem;
  //   margin: 0 auto;
  position: relative;
  @include desktop {
    margin-left: 4rem;
    margin: 0 auto;
  }
  @include mobile {
    margin-left: 0;
    flex: 1;
    width: 100%;
    min-width: 100%;
    max-width: 100%;

  }
  &_Container {
    display: flex;
    width: 17rem;
    height: 10.4rem;
    padding: 0.8rem 4rem 0.8rem 4.1rem;
    justify-content: center;
    align-items: center;
    @include mobile {
      height:8rem;
      width: 8rem;
    }
  }
  &_Image {
    width: 8.8rem;
    height: 8.8rem;
    @include mobile {
      height: 5rem;
      width: 5rem;
    }
  }
  &_Box {
    position: absolute;
    height: 5.2rem;
    width: 5.2rem;
    background: $info-light-color;
    border-radius: 50%;
    left: -3rem;
    gap: 3.6rem;
    @include mobile {
      gap:1rem;
      height:4rem;
      width: 4rem;
      left:-2rem;

    }
    @include flex-container(row, nowrap, center, center);
    p {
      color: #fff;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &_Right {
    gap: 0.8rem;
    @include flex-container(column, nowrap, flex-start, flex-start);
    @include mobile{
      gap:.3rem;
    }
    h1 {
      color: #000;

      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: var(--Color-gray-600, #4d4d4d);
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
