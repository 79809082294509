.button-primary {
  display: flex;
  height: 5.4rem;
  padding: 1.2rem 2.4rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: $border-radius-4;
  background: $primary-dark-color;
  color: $white-color-1;
  border: none;
  outline: none;
  font-family: Inter;
  @include set-font-line-height(1.6rem);
  font-style: normal;
  font-weight: 500;
  @include desktop {
    padding: 1rem 2rem;
  }
  @include tablet {
    padding: 0.5rem 1.2rem;
    @include set-font-line-height(1.3rem);
  }
  &:hover {
    cursor: pointer;
  }
}

.button-primary-1 {
  display: flex;
  width: 20.6rem;
  height: 7rem;
  padding: 1.2rem 2.4rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-top-left-radius: $border-radius-4;
  border-top-right-radius: $border-radius-4;
  background: $primary-dark-color;
  color: $white-color-1;
  border: none;
  outline: none;
  font-family: Inter;

  @include set-font-line-height(1.6rem);
  font-style: normal;
  font-weight: 500;

  @include desktop {
    padding: 1rem 2rem;
  }
  @include tablet {
    padding: 0.5rem 1.2rem;
    @include set-font-line-height(1.3rem);
  }
  &:hover {
    cursor: pointer;
  }
}
.button-primary-2 {
  display: flex;
  height: 4.8rem;
  padding: 1.2rem 2.4rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: $border-radius-4;
  background: $primary-dark-color;
  color: $white-color-1;
  border: none;
  outline: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  @include set-font-line-height(1.6rem);
  @include desktop {
    padding: 1rem 2rem;
  }
  @include tablet {
    padding: 0.5rem 1.2rem;
    @include set-font-line-height(1.3rem);
  }
  &:hover {
    cursor: pointer;
  }
}

.planbutton {
  margin: 0 auto;
  button {
    border: none;
    display: flex;
    width: 17rem;
    height: 4.8rem;
    padding: 1.2rem 2.4rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 0.8rem;
    background: var(--Color-cyan-800, #2cab93);

    color: var(--Color-gray-0, #fff);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    &:hover {
      cursor: pointer;
    }
  }
}

.contactsubmit1 {
  border: none;
  display: flex;
  border-radius: 0.8rem;
  background: var(--Color-cyan-800, #2cab93);
  width: 18rem;
  height: 4.8rem;
  padding: 1.2rem 2.4rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--Color-gray-0, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem; /* 150% */
  cursor: pointer;
}

.button-secondary {
  display: flex;
  width: auto;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Color-gray-0, #fff);
  border:none;
  outline:none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  background: var(--Color-gray-800, #1a1a1a);
}
