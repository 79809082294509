.contact {
  max-width: $max-width;
  margin: 2rem auto;
  padding: 3.4rem 12rem;
  @include desktop {
    padding: 3.4rem 5rem;
  }
  @include tablet {
    padding: 3.4rem 2.4rem;
  }
  @include mobile {
    padding: 3.4rem 2rem;
  }
  &_container {
    display: flex;
    max-width: 120rem;
    max-width: 120rem;
    padding: 5rem 3.2rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.8rem;
    background: #fff;
    box-shadow: 0px 0px 1.2rem 0.2rem rgba(0, 0, 0, 0.05);
    @media screen and (max-width: $tablet-width) {
      flex-direction: column;
      gap: 3.2rem;
      padding: 3.4rem 2.4rem;
    }

    .contactinfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.4rem;
      flex-grow: 1;
      &_header {
        color: #000;
        font-family: Inter;
        font-size: 3.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem; /* 125% */
        max-width: 47.9rem;
        @include tablet {
          max-width: 100%;

          font-size: 2.4rem;
          line-height: 3.6rem;
        }
        span {
          color: var(--Color-cyan-800, #2cab93);
        }
      }
      &_desc {
        color: var(--Color-gray-600, #4d4d4d);
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
        max-width: 48.2rem;
        @include tablet {
          max-width: 100%;

          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
      &_direct {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8rem;
        .number,
        .email {
          display: flex;
          gap: 0.4rem;
          .content {
            color: var(--Color-gray-600, #4d4d4d);
            font-family: Inter;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.4rem; /* 150% */
          }
        }
      }
      &_connect {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        .header {
          color: #000;
          font-family: Inter;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2.4rem; /* 150% */
        }
        .logo {
          display: flex;
          align-items: flex-start;
          gap: 2rem;
        }
      }
    }
    &_form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.4rem;

      .contactname,
      .contactemail {
        display: flex;
        width: 50.6rem;
        padding: 1.2rem 1.6rem;
        align-items: center;
        gap: 2.4rem;
        border-radius: 3.2rem;
        border: 0.05rem solid var(--Color-gray-50, #e6e6e6);
        background: #fff;
        @media screen and (max-width: 1295px) {
          max-width: 40rem;
        }
        @include tablet {
          width: 32rem;
        }
        @media screen and (max-width: $tablet-width) {
          width: 50rem;
        }
        @include mobile {
          width: 100%;
        }
        div {
          width: 100%;
          input {
            color: var(--Color-gray-600, #4d4d4d);
            font-family: Inter;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.4rem; /* 150% */
            width: 100%;
            height: 2.4rem;
            border: none;
            outline: none;
          }
        }
      }

      textarea {
        display: flex;
        color: var(--Color-gray-600, #4d4d4d);
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        padding: 1.2rem 1.6rem;
        width: 50.6rem;
        min-height: 13rem;
        border: 0.05rem solid var(--Color-gray-50, #e6e6e6);
        background: #fff;
        border-radius: 2rem;
        overflow: auto;
        resize: vertical;
        outline: none;
      }

        @media screen and (max-width: 1295px) {
          textarea{
          max-width: 40rem;
          }
        }
        @include tablet {
          textarea{
          width: 32rem;
          }
        }
        @media screen and (max-width: $tablet-width) {
          textarea{
          width: 50rem;
          }
        }

        @include mobile {
          textarea{
          width: 33rem;
          }
        }

        @media screen and (max-width: 320px) {
          textarea{
            width: 28rem;
            }
        }
        

      

      .contactsubmit {
        button {
          border: none;
          display: flex;
          border-radius: 0.8rem;
          background: var(--Color-cyan-800, #2cab93);
          width: 18rem;
          height: 4.8rem;
          padding: 1.2rem 2.4rem;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          color: var(--Color-gray-0, #fff);
          /* Body/medium */
          font-family: Inter;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 500;
          line-height: 2.4rem; /* 150% */
          cursor: pointer;
        }
      }
    }
  }
}
