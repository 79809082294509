.Form {
  max-width: 78.8rem;

  //   height: 10rem;

  margin: 4.2rem auto;
  border-radius: 0.8rem;
  background: #fff;
  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.1);
  @include tablet {
    margin: 3rem auto;
  }
  &_Heading {
    display: flex;
    padding: 1.6rem 4rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    background: #eaf7f5;
    @include tablet {
      padding: 1rem 2rem;
    }
    h1 {
      color: var(--Color-gray-600, #4d4d4d);

      @include set-font-line-height(1.6rem);

      font-weight: 700;
    }
  }
  &_Body {
    display: flex;
    padding: 1.6rem 5.2rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1.6rem;
    align-self: stretch;
    @include tablet {
      padding: 1rem 2rem;
    }
    &_Button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_Heading {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      width: 100%;
      &_Progress {
        background: var(--Color-cyan-800, #2cab93);

        height: 5px;
        border-radius: 8px;
      }
      &_Container {
        max-width: 684px;
        width: 100%;
        height: 5px;
        border-radius: 8px;
        background: var(--Color-gray-50, #e6e6e6);
      }
      p {
        color: var(--Color-gray-600, #4d4d4d);
        text-align: center;
        @include set-font-line-height(1.6rem);

        font-weight: 400;
      }
      span {
        color: var(--Color-gray-200, #b3b3b3);
        text-align: center;
        @include set-font-line-height(1.6rem);
        font-style: normal;
        font-weight: 400;
      }
    }
    &_Body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1.2rem;
      align-self: stretch;
      &_Hello {
        display: flex;
        justify-content: space-between;
      }
      &_Flex {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        &_Input {
          height: 2.3rem;
          width: 2.3rem;
          margin-right: 1rem;
        }
        p {
          @include set-font-line-height(1.6rem);
        }
      }
      &_ButtonPrimary1 {
        flex: 1;
        width: 100%;
        padding: 1rem;
        background: none;
        border: 1px solid $primary-dark-color;
        outline: none;
        color: $primary-dark-color;
        // border:none;
        border-radius: $border-radius-2;
        display: flex;
        align-items: center;
        &_Radio {
          width: 2.5rem;
          height: 2.5rem;
        }
        &_Right {
          margin-left: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h1 {
            @include set-font-line-height(1.8rem);
          }
          p {
            text-align: start;
            @include set-font-line-height(1.5rem);
          }
        }
      }
      &_Button1 {
        flex: 1;
        width: 100%;
        padding: 1rem;
        background: none;
        border: 1px solid var(--Color-gray-50, #e6e6e6);
        outline: none;
        // border:none;
        border-radius: $border-radius-2;
        display: flex;
        align-items: center;
        &:hover {
          border: 1px solid $primary-dark-color;
          color: $primary-dark-color;
        }
        &_Radio {
          width: 2.5rem;
          height: 2.5rem;
        }
        &_Right {
          margin-left: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h1 {
            @include set-font-line-height(1.6rem);
            // font-weight
          }
          p {
            text-align: start;
            @include set-font-line-height(1.5rem);
          }
        }
      }
      &_ButtonPrimary {
        flex: 0 0 47%;
        padding: 1rem;
        background: none;
        border: 1px solid $primary-dark-color;
        outline: none;
        color: $primary-dark-color;
        // border:none;
        border-radius: $border-radius-2;
        display: flex;
        align-items: center;
        &_Radio {
          width: 2.5rem;
          height: 2.5rem;
        }
        &_Right {
          margin-left: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h1 {
            @include set-font-line-height(1.8rem);
          }
          p {
            text-align: start;
            @include set-font-line-height(1.5rem);
          }
        }
      }
      &_Button {
        flex: 0 0 47%;
        padding: 1rem;
        background: none;
        border: 1px solid var(--Color-gray-50, #e6e6e6);
        outline: none;
        // border:none;
        border-radius: $border-radius-2;
        display: flex;
        align-items: center;
        &:hover {
          border: 1px solid $primary-dark-color;
          color: $primary-dark-color;
        }
        &_Radio {
          width: 2.5rem;
          height: 2.5rem;
        }
        &_Right {
          margin-left: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h1 {
            @include set-font-line-height(1.8rem);
          }
          p {
            text-align: start;
            @include set-font-line-height(1.5rem);
          }
        }
      }
      &_InputDanger {
        display: flex;
        height: 4.8rem;
        padding: 1.2rem 1.6rem;
        width: 100%;
        gap: 1.2rem;
        align-self: stretch;
        border-radius: 0.4rem;
        border: 1px solid #e52222;
        outline: none;
        color: #e52222;
        background: none;

        /* label/regular */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &_InputBox {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        p {
          color: var(--Color-gray-600, #4d4d4d);
          /* Button/regular */
          font-family: Inter;
          @include set-font-line-height(1.6rem);
          font-style: normal;
          font-weight: 400;
          margin-bottom: 2px;
        }
      }
      &_Input {
        display: flex;
        height: 4.8rem;
        padding: 1.2rem 1.6rem;
        width: 100%;
        gap: 1.2rem;
        align-self: stretch;
        border-radius: 0.4rem;
        border: 1px solid var(--Color-gray-50, #e6e6e6);
        outline: none;
        color: var(--Color-gray-200, #918888);
        // color: var(--Color-gray-50, #e6e6e6);;
        background: none;

        /* label/regular */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.text-danger {
  color: #e52222;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
