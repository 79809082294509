.Blogs {
  max-width: $desktop-width-small;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &_Heading {
    @include set-font-line-height(32px);
    font-weight: 700;
    font-style: normal;
    color: #000;
    span {
      color: var(--Color-cyan-800, #2cab93);
    }
  }
  &_Row {
    display: flex;
    position: relative;
    gap: 2.5rem;

    @include tablet {
      flex-direction: column;
      // flex-direction: column-reverse;
      padding: 3rem;
    }
  }
  &_Hello {
    display: flex;
    flex-direction: column;
    &_Image {
      margin-bottom: 2rem;
      max-width: 670px;
    }
  }
  &_Left {
    max-width: 618px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    p {
      @include set-font-line-height(16px);
      font-style: normal;
      font-weight: 400;
      color: var(--Color-gray-600, #4d4d4d);
    }
    h1 {
      @include set-font-line-height(24px);
      font-weight: 700;
      font-style: normal;
    }
    strong {
      @include set-font-line-height(16px);
      font-style: normal;
      color: var(--Color-gray-600, #4d4d4d);

      // font-weight: 400;
    }
    &_Hello {
      @include set-font-line-height(16px);
      font-style: normal;
      font-weight: 400;
      color: var(--Color-gray-600, #4d4d4d);
    }
  }
  &_Right {
    position: sticky;
    top: 12rem;
    right: 0;
    border-radius: 8px;
    background: var(--Color-gray-0, #fff);
    box-shadow: 0px 2px 24px 2px rgba(0, 0, 0, 0.1);
    flex: 1;
    max-width: 670px;
    height: 100%;
    padding-bottom: 2rem;
    @include tablet {
      position: inherit;
      padding-bottom: 1rem;
      top:0;
      display: none;
      // margin: 0 auto;
    }
    &_Heading {
      border-radius: 8px 8px 0px 0px;
      background: var(--Color-cyan-800, #2cab93);
      display: flex;
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      h3 {
        color: var(--Color-gray-0, #fff);
        @include set-font-line-height(24px);

        font-style: normal;
        font-weight: 700;
      }
      p {
        @include set-font-line-height(14px);
        color: var(--Color-gray-0, #fff);
        font-weight: 500;
      }
    }
    &_Box {
      display: flex;
      padding: 16px;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      background: #fff;
    }
    &_InputBox {
      display: flex;
      width: 306px;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      p {
        color: var(--Color-gray-600, #4d4d4d);
        /* Button/regular */
        font-family: Inter;
        @include set-font-line-height(16px);
        font-style: normal;
        font-weight: 400;
      }
    }
    &_Input {
      display: flex;
      height: 48px;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;
      @include set-font-line-height(16px);
      border: 1px solid var(--Color-gray-50, #e6e6e6);
    }
    &_Button {
      margin: 0 auto;
    }
  }
}
