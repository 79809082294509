.Banner {
  width: 100%;
  position: relative;
  height: 27.2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &_Image1 {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--Color-cyan-800, #2cab93);
    height: 27.2rem;
    width: 100vw;
  }
  &_Image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    overflow: hidden;
    height: auto;

    &_Img {
      height: auto;
      min-height: 25rem;
      width: 100%;
      background-repeat: no-repeat; /* Ensures the background image does not repeat */
      background-size: cover; /* This scales the background image to cover the entire element */
      background-position: center;
    }
  }
  &_Content {
    position: absolute;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    top: 0;
    background: none;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    padding: 0px 12rem;
    position: relative;
    @include desktop {
      padding: 1.5rem;
    }
    @include tablet {
      padding: 1.5rem;
    }
    &_Heading {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: var(--Color-gray-0, #fff);
      align-self: flex-start;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    h2 {
      max-width: 584px;
      color: var(--Color-gray-0, #fff);
      /* H2/bold */
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      align-self: flex-start;
      @include mobile {
        @include set-font-line-height(3.2rem);
      }
    }
    h1 {
      @include set-font-line-height(4rem);
      // align-self: flex-start;
      color: $white-color-1;
      margin-bottom: 10px;
      @include mobile {
        @include set-font-line-height(3.2rem);
      }
    }

    &_Button {
      // align-self: flex-end;
    }
  }
}

.HowItStarted {
  max-width: 100vw;
  background: var(--Color-cyan-0, #eaf7f5);
  &_Content {
    max-width: $max-width;
    display: flex;
    padding: 60px 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 0 auto;
    @include tablet {
      padding: 60px 60px;
    }
    @include mobile {
      padding: 60px 30px;
      gap: 10px;
    }
    h1 {
      color: #000;

      text-align: center;
      /* H3/bold */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
    p {
      max-width: 992px;
      color: var(--Color-gray-600, #4d4d4d);
      text-align: center;
      /* Body/regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.PlanVision {
  background: #f6f6f6;
  max-width: 100vw;
  &_Content {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    padding: 6rem 12rem;
    align-items: flex-start;
    gap: 3.6rem;
    @include tablet {
      padding: 0.6rem 3rem;
    }
    @include mobile {
      padding: 6rem 2rem;
      flex-direction: column;
      gap: 8.4rem;
    }
    &_Box {
      width: 58.2rem;
      height: 37.6rem;
      display: flex;
      flex-direction: column;
      // background: red;
      position: relative;
      @include mobile {
        width: 100%;
        height: auto;
      }
      &_Image {
        align-self: flex-start;
        top: 0;
        left: 0;
      }
      &_Box {
        display: inline-flex;
        padding: 3.2rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
        background: var(--Color-cyan-800, #2cab93);
        max-width: 41.9rem;
        height: auto;
        position: absolute;
        right: 0;
        bottom: 1rem;
        z-index: 1;
        @include tablet {
          transform: translateY(2rem);
          margin-left: 1rem;
        }
        @include mobile {
          padding: 1rem;
          transform: translateY(6rem);
        }
        h1 {
          color: var(--Color-gray-0, #fff);
          /* H4/bold */
          font-family: Inter;
          font-size: 2.4rem;
          font-style: normal;
          font-weight: 700;
          line-height: 3rem;
        }
        p {
          color: var(--Color-gray-0, #fff);
          /* Body/regular */
          font-family: Inter;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem;
        }
      }
      &_Box1 {
        width: 183px;
        height: 148px;
        background: #fe9900;
        position: absolute;
        bottom: 0;
        left: 39%;
        transform: translateX(-39%);
        // z-index: -1;
        @include mobile {
          display: none;
        }
      }
    }
  }
}

.WhyChooseUs {
  display: flex;
  max-width: 144rem;
  margin: 0 auto;
  padding: 6rem 12rem;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  @include tablet {
    padding: 6rem 4rem;
  }
  @include mobile {
    padding: 6rem 2rem;
  }

  h1 {
    color: #000;

    /* H3/bold */
    font-family: Inter;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
  }
  &_Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    align-self: stretch;
    width: 100%;
    @include tablet {
      gap: 2rem;
    }
    @include mobile {
      gap: 4rem;
    }
    &_Image {
      width: 12.6rem;
      height: 12.6rem;
    }
    &_Box {
      h3 {
        color: var(--Color-gray-600, #4d4d4d);
        /* Body/bold */
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem;
      }
      p {
        max-width: 63.7rem;
        color: var(--Color-gray-600, #4d4d4d);
        /* Body/regular */
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
  }
  &_BoxWrapper {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    align-self: stretch;
    padding-right: 20rem;
    @include mobile {
      padding-right: 2rem;
      flex-direction: column;
    }
  }
  &_BoxWrapper1 {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    align-self: flex-end;
    padding-left: 20rem;
    padding-right: 0rem;
    @include mobile {
      padding-left: 2rem;
      flex-direction: column-reverse;
    }
  }
}

.GetAQuote {
  background: var(--Color-cyan-0, #eaf7f5);
  max-width: 100vw;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
 

  &_Content {
    display: flex;
    max-width: 1200px;
    @include tablet{
      padding-bottom: 2rem;
      padding-top: 1rem;
    }

    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    background: var(--Color-cyan-800, #2cab93);
    img{
      width: 100%;
      height: auto;
      @include mobile {
        width: 8rem
      }
    }

    &_Box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.6rem;
      align-self: stretch;
      h1 {
        color: var(--Color-gray-0, #fff);
        /* H4/bold */
        font-family: Inter;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3rem;
        @include mobile {
          font-size: 2rem;
          text-align: center;
        }
        

      }
    }
  }
}
