.blogs {
  max-width: 100vw;

  .blogcontainerlist {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 2.1rem 15.1rem;
    max-width: $max-width;
    @media screen and (max-width: 1310px){
      padding: 2.1rem 10.1rem;
    }
    @include desktop {
      padding: 2.1rem 6.8rem;
    }
    @include mobile{
      padding: 2.1rem 2.1rem;
    }

    h1 {
      color: #000;
      font-family: Inter;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem; /* 125% */
    }

    .bloglist {
      margin: 0 auto;
      width: 100%;
      display: flex;
      gap: 4rem;
      flex-wrap: wrap;
      // @include mobile{
      //   justify-content: center;
      // }
    }
  }

  .blogcontainer {
    flex-shrink: 1;
    border-radius: 0.8rem;
    background: var(--Color-gray-0, #fff);
    box-shadow: 0px 0px 2.4rem 0.2rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 25rem; /* Minimum width of the card */
    max-width: 30.7rem; /* Maximum width of the card */
    @include desktop {
      max-width: 28rem;
    }
    @media screen and (max-width: 1060px){
      max-width: 26rem;
      
    }@include tablet {
      max-width: 30.7rem;
    }
    @include mobile {
      max-width:26rem;
    }
    @media screen and (max-width: 560px){
      max-width: 30rem;
      
    }

    &_image {
      width: 100%;
      border-radius: 0.8rem 0.8rem 0px 0px;
      overflow: hidden;
      img {
        max-width: 100%;
        object-fit: cover;
      }
    }

    &_content {
      display: flex;
      padding: 1.6rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
      align-self: stretch;

      .header {
        color: #000;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem; /* 150% */
      }

      .line {
        max-width: 27.533rem;
        height: 0.1rem;
        background: var(--Color-gray-100, #ccc);
      }

      .created {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .date {
          color: #000;
          font-family: Inter;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.1rem; /* 150% */
        }

        .share {
          img {
          }
        }
      }
    }
  }

  @include tablet {
    
    .blogcontainer {
      width: calc((100% - 4rem) / 2); /* Display two cards in a row with a gap of 4rem */
    }
  }

  @media screen and (max-width: 670px) {
    .blogcontainer {
      width: calc(100% - 4rem); /* Display one card per row with a gap of 4rem */
    }
  }
}
