.Offers {
  margin-top: 3.6rem;

  background: var(--Color-cyan-0, #eaf7f5);
  &_Content {
    max-width: $max-width;
    margin: 0 auto;
    padding: 6rem 12rem 12rem 12rem;
    @include maxWidth {
      padding: 4rem 4rem 10rem 4rem;
    }
    @include flex-container(column, nowrap, center, center);
    gap: 1.6rem;
  }

  h1 {
    @include set-font-line-height(2.4rem);
    font-weight: 400;
    text-align: center;
    span {
      font-weight: 700;
    }
  }
  p {
    color: var(--Color-gray-600, #4d4d4d);
    text-align: center;
    @include set-font-line-height(1.6rem);
    font-weight: 400;
  }
  &_Data {
    width: 100%;
    @include flex-container(row, wrap, center, center);
    gap: 1.2rem;
  }
}

.OfferSingle {
  border-radius: $border-radius-4;
  box-shadow: 0px 0px 3.2rem 2px rgba(0, 0, 0, 0.08);
  @include flex-container(row, nowrap, flex-start, center);
  padding: 1.2rem 2.4rem;
  min-width: 27.3rem;
  max-width: 35rem;
  margin: 0 auto;
  flex: 1;
  height: 7rem;
  background: $white-color-1;
  p {
    color: var(--Color-gray-700, #333);
    font-size: 1.6rem;
    line-height: normal;
    font-weight: 700;
    margin-left: 1rem;
  }
}
