.whycontainer {
  max-width: $max-width;
  margin: 4rem auto;
  background: #f9fffe;
  .why {
    width: 100%;
    background: #f9fffe;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    padding: 0rem 1.2rem;
    &_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.9rem;
      &_title {
        color: #000;
        text-align: center;

        /* H3/bold */
        font-family: Inter;
        font-size: 3.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4rem; /* 125% */
        span {
          color: var(--Color-cyan-800, #2cab93);

          /* H3/bold */
          font-family: Inter;
          font-size: 3.2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 4rem;
        }
      }
      &_desc {
        color: var(--Color-gray-600, #4d4d4d);

        /* Body/regular */
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
      }
    }
    &_content {
      margin: 0 auto;
      display: flex;
     
      align-items: flex-start;
      gap: 6.3rem;
      @include mobile{
        gap: 0;

      }
      &_logo {
        max-width: 65.7587rem;
        height: 42.7rem;
        margin: 0 auto;
        display: flex;
        position: relative;
        background: url("./../../assets//whychooseus//logo2.svg") lightgray -227.243px -0.174px /
            197.124% 100% no-repeat,
          #d9d9d9;
        
        .logo1 {
          display: flex;
          max-width: 24.6rem;
          height: 35rem;
          padding: 4rem 1.6rem;
          flex-direction: column;
          margin-top: 2.7rem;
          align-items: flex-start;
          gap: 2.4rem;
          background: var(--Color-cyan-800, #2cab93);
          z-index: 1;
          flex-shrink: 0;
          @include tablet{
            margin-right: 2rem;
          }
          @include mobile{
            display: none;
          }

          div {
            width: 100%;
            color: var(--Color-gray-0, #fff);
            font-family: "Inter";
            font-size: 3.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 5rem; /* 138.889% */
          }
        }
        .logo2 {
          transform: translateX(-5rem);
          @include tablet{
            display: none;
          }
        }
        .logo3 {
          transform: translate(-15.5rem, 20.6rem);
          z-index: 1;
          @include desktop {
            display: none;
          }
        }
        .logo4 {
          width: 19.8764rem;
          height: 18.6077rem;
          transform: translate(-41.1rem, 19.1rem);
          background: #fe9900;
          z-index: 0.5;
          @include desktop {
            display: none;
          }
        }
      }

      &_reason {
        @include mobile{
          text-align: center;
        }
        max-width: 47.9rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3.6rem;
        flex-grow: 1;
        width: 100%;
        margin: 0 auto;
        @include tablet{
          gap: 2rem;
          
        }
        &_desc {
          @include mobile{
            margin: 0 auto;
          }
          width: 100%;
          color: var(--Color-gray-600, #4d4d4d);

          /* Body/regular */
          font-family: Inter;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem; /* 150% */
          text-wrap: wrap;
          @include desktop {
            flex-grow: 1;
            margin: 0 auto;
          }
        }
        &_flex {
          @include tablet{
            font-size: 2.4rem;
            line-height: 3.6rem;
          }
          @include mobile{
            text-align: center;
          }
          color: #fe9900;

          /* H3/bold */
          font-family: Inter;
          font-size: 3.2rem;
          font-style: normal;
          font-weight: 700;
          line-height: 4rem; /* 125% */
        }
        .discover_button {
          border: none;
          display: flex;
          width: 17rem;
          height: 4.8rem;
          padding: 1.2rem 2.4rem;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          border-radius: 0.8rem;
          background: var(--Color-cyan-800, #2cab93);

          color: var(--Color-gray-0, #fff);
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2.4rem;
        }
      }
    }
  }
}
.reasoncontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.2rem;
  align-self: stretch;
  @include mobile{
    margin: 0 auto;
  }
  .reason {
    display: flex;
    padding: 1.6rem 3.2rem;
    align-items: flex-start;
    gap: 1.6rem;
    align-self: stretch;
    background: var(--Color-cyan-0, #eaf7f5);
    &_title {
      color: var(--Color-gray-600, #4d4d4d);

      /* Body/medium */
      font-family: Inter;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.4rem; /* 150% */
    }
  }
}
