@mixin flex-container(
  $direction: row,
  $wrap: nowrap,
  $justify-content: flex-start,
  $align-items: stretch,
  $align-content: stretch
) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin flex-item(
  $order: 0,
  $flex-grow: 0,
  $flex-shrink: 1,
  $flex-basis: auto,
  $align-self: auto
) {
  order: $order;
  flex-grow: $flex-grow;
  flex-shrink: $flex-shrink;
  flex-basis: $flex-basis;
  align-self: $align-self;
}

@mixin set-font-line-height(
  $font-size: $default-font-size,
  $ratio: $line-height-ratio
) {
  font-size: $font-size;
  line-height: $font-size * $ratio;
}

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$desktop-width-small - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin maxWidth {
  @media (max-width: #{$max-width}) {
    @content;
  }
}


