/* we are using reset ruls of bootstrap,
if you need anything override it here 
or add new */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;

}
html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  @include tablet {
    font-size:58%;
  }
  overflow-x: hidden;
  
}
