.footer {
  width: 100vw;
  background: var(--Color-gray-800, #1a1a1a);
  overflow: hidden;
  &_content {
    display: flex;
  max-width: $max-width;
    margin: 0 auto;
    padding: 6rem 12rem 0rem 12rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    @include desktop {
      padding: 3rem 6rem 0rem 3rem;
    }
    @include tablet {
      gap: 2rem;
    }
  }
  &_links {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 27.5rem;
    @media screen and (max-width: 1270px) {
      gap: 14rem;
    }
    @include desktop {
      gap: 6rem;
    }
    @include tablet {
      gap: 2rem;
      flex-wrap: wrap;
    }
    &_beemalaya {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3.2rem;

      @include tablet {
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        flex-direction: row;
      }
      @include mobile {
        width: 100%;
        flex-direction: column;
        gap: 3rem;
      }
      .companylogo {
        display: flex;
        max-width: 23.5rem;
        height: 5.4rem;
        padding-right: 1.1032rem;
        align-items: center;
        gap: 2.03rem;
        @include mobile {
          // margin: 0 auto;
          align-items: flex-start;
          justify-content: center;
          align-items: center;
          gap: 3rem;
        }

        div {
          color: var(--Color-gray-0, #fff);
          text-align: center;
          font-family: Angkor;
          font-size: 2.601rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
         
        }
      }
      .sociallinks {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        @include mobile {
          // margin: 0 auto;
        }
        @include tablet {
          gap: 1rem;
        }
      }
    }
  }
  &_terms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.2rem;
    width: 100%;
    &_desc {
      color: #fff;
      text-align: center;

      /* Body/regular */
      font-family: Inter;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.4rem; /* 150% */
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #fff;
    }
    &_list {
      width: 100%;
      display: flex;
      max-width: 120rem;
      padding-bottom: 2rem;
      align-items: flex-start;
      justify-content: space-between;
      gap: 27.4rem;

      @include desktop {
        gap: 6rem;
      }
      @include tablet {
        gap: 2rem;
      }

      .listelement {
        color: #fff;
        text-align: center;

        /* Body/regular */
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
      }
    }
  }
}

.links {
  display: flex;
  align-items: flex-start;

  gap: 6.2rem;
  @include desktop {
    gap: 4rem;
  }

  @include tablet {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @include mobile {
    gap: 2rem;
  }
  &_getinsured,
  &_quick,
  &_contact {
    width: 18.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
    @include mobile {
      margin: 0 auto;
      width: 100%;
    }
    &_header {
      align-self: stretch;
      color: #fff;
      font-family: Inter;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem; /* 125% */
      @include tablet {
        font-size: 2.4rem;
        line-height: 3rem;
      }
      @include mobile {
        width: 100%;
        // margin: 0 auto;
        // text-align: center;
      }
    }

    .get_insurance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;
      align-self: stretch;
      &_item {
        color: #fff;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 150% */
        text-decoration: none;
        @include tablet {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        @include mobile {
          // align-items: center;
          text-align:start;
          // margin: 0 auto;
        }
      }
    }
  }
}

#footer_directcontact {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  .directcontact {
    text-wrap: wrap;
  }
}
