.landing {
  width: 100vw;
  // max-width: $max-width; /* 1440px / 10 */

  /* 667px / 10 */
  background: #f6f6f6;

  &_cover {
    max-width: $max-width;
    width: 100%;
    height: 66.7rem;
    display: flex;
    margin: 0 auto;
    @include tablet{
      height: auto;
    
    }
  }
  &_content {
    padding-left: 12rem; /* 120px / 10 */
    padding-top: 11.2rem; /* 112px / 10 */
    padding-right: 10.4rem; /* 242px / 10 */
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    // max-width: 68.5rem; /* 685px / 10 */
    gap: 4rem; /* 40px / 10 */
    @media screen and (max-width: 1250px) {
      padding-right: 3rem;
    }
    @include desktop {
      padding-left: 4rem;
    }
    @include tablet {
      margin: 0 auto;
      max-width: 65.3rem;
      padding-bottom: 5rem;
    }
    @include mobile {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      padding-top: 4rem;
    }
    .content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.4rem; /* 24px / 10 */
      &_header {
        color: #000;
        font-family: Inter;
        font-size: 4rem; /* 40px / 10 */
        font-style: normal;
        font-weight: 700;
        line-height: 5rem; /* 50px / 10 */
        @include tablet {
          font-size: 3.2rem;
          line-height: 4rem;
          text-align: start;
        }
        span {
          color: var(--Color-cyan-800, #2cab93);
          font-family: Inter;
          font-style: normal;
          font-weight: 700;
        }
      }
      &_desc {
        max-width: 58.3rem; /* 583px / 10 */
        color: var(--Color-gray-600, #4d4d4d);
        font-family: Inter;
        font-size: 1.6rem; /* 16px / 10 */
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; /* 24px / 10 */
      }
    }
    &_insurance {
      width: 100%;
      .container {
        width: 100%;
        display: inline-flex;
        padding: 1.2rem 2.4rem; /* 12px / 10, 24px / 10 */
        align-items: flex-start;
        gap: 3.2rem; /* 32px / 10 */
        flex-wrap: wrap;
        max-width: 70rem;
        @include desktop {
          gap: 2rem;
          padding: 1.2rem 2rem;
        }
        @media screen and (max-width: 1030px) {
          gap: 1.3rem;
          padding: 1.2rem 1.1rem;
        }
        &_insurance {
          cursor: pointer;
          display: flex;
          width: 17rem; /* 170px / 10 */
          padding: 1.6rem 0.8rem; /* 16px / 10, 8px / 10 */
          flex-direction: column;
          align-items: center;
          gap: 0.8rem; /* 8px / 10 */
          border-radius: 0.8rem; /* 8px / 10 */
          background: #fff;
          box-shadow: 0 0 2.4rem 0.2rem rgba(0, 0, 0, 0.1); /* 0px 0px 24px 2px rgba(0, 0, 0, 0.1) / 10 */
          @include desktop {
            width: 16rem;
          }
          @include tablet {
            margin: 0 auto;
          }
          @include mobile {
            width: 14rem;
          }
          .insurance_title {
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 1.6rem; /* 16px / 10 */
            font-style: normal;
            font-weight: 700;
            line-height: 2.4rem; /* 24px / 10 */
          }
        }
      }
    }
  }
  &_banner {
    width: 393px;
    height: 667px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &_left {

      position: absolute;
      top: 0;
      left: 0;
    }
    &_right {
      z-index: 10;
      margin-top: 3rem;
      margin-left: -13rem;
      width: 41.2rem;
      height: 60rem;
      // position: absolute;
      // left:-13rem;
      // top:3rem;
      @include desktop {
        display: none;
      }
    }
    @include tablet {
      display: none;
    }
  }
}
