.get_quote {
  width: 144rem;
  height: 20rem;
  flex-shrink: 0;
  background: var(--Color-cyan-0, #eaf7f5);
  display: flex;
  &_content {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 2.1rem;
    padding: 6.2rem 12rem 3.9rem 9.1rem;
    &_header {
      color: #000;
      font-family: Inter;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 3rem;
    }
  }
  &_contactimg {
    display: flex;
    width: 63.1rem;
    height: 20rem;
    flex-shrink: 0;
    background: url("./../../assets/needhelp.svg");
    padding-left: 19.4rem;
    padding-top: 5.1rem;
    gap:2.4rem;

    .call {
      img {
        padding: 1.8rem;
        border-radius: 50%;
        border: 1px solid white;
      }
    }
    .contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.4rem;
      color: var(--Color-gray-0, #fff);

      /* H3/bold */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
      .number {
        font-weight: 400;
      }
    }
  }
}
