.testimonials {
  max-width: 100vw;
  margin: 0 auto;


  background: linear-gradient(
      0deg,
      rgba(246, 246, 246, 0.8) 0%,
      rgba(246, 246, 246, 0.8) 100%
    )
    lightgray 0% 0% / 10rem 10rem repeat;
  &_content {
    display: flex;
    max-width: $max-width;
    margin: 0 auto;
    padding: 6rem 12rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    @include tablet {
      padding: 6rem 6.4rem;
    }
    @include mobile {
      padding: 6rem 2.4rem;
    }

    max-width: $max-width;

    &_header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
      .happy {
        color: #000;
        font-family: Inter;
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 5rem;
      }
      .hear {
        color: var(--Color-cyan-800, #2cab93);
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
    &_desc{
      max-width: $max-width;
      width: 100%;
    }
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  max-width: $max-width;
  background: linear-gradient(0deg, rgba(246, 246, 246, 0.80) 0%, rgba(246, 246, 246, 0.80) 100%) lightgray 0% 0% / 100px 100px repeat;
  &_logo {
    width: 5.6rem;
    height: 5.6rem;
  }
  &_desc {
    color: #606060;
    text-align: center;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 57rem;
    @include mobile {
      width: 100%;
    }
  }
  &_profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    &_image {
      width: 6.9365rem;
      height: 7.2rem;
      border-radius: 7.2rem;
      background: url("./../../assets//testimonial/john.svg") lightgray 50% /
        cover no-repeat;
    }
    &_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;

      .name {
        color: #000;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.4rem;
      }
      .position {
        color: #000;
        font-family: Inter;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
      }
    }
  }
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1.8rem;
  background: #fff;
  @include mobile{
    font-size: 1rem;
  }
 

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}




