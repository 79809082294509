.cardcontainer {
  display: flex;
  flex-direction: column;

  gap: 2.4rem;
  @include desktop {
    gap: 2rem;
  }
  @include tablet {
    padding: 0rem;
    gap:1rem;
  }
  &_card {
    border-radius: 0.8rem;
    border: 0.5px solid var(--Color-gray-50, #e6e6e6);
    background: #fff;
    box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    gap: 1.2rem;
    
    &_insurance {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4.8rem;
      padding-bottom: 2.4rem;
      padding-top: 2.4rem;
      padding-left: 3.2rem;
      padding-right: 3.2rem;
      @include tablet{
        padding: 1.5rem;
        gap: 3rem;
      }
      @include mobile{
        flex-direction: column;
      }
      &_company {
        display: flex;
        min-width: 28.8rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        flex-shrink: 0;
        @include tablet{
          min-width: 24rem;
        }

        div {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2.4rem;
        }
      }
      &_policycontent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        &_box {
          color: var(--Color-gray-600, #4d4d4d);
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;

          line-height: 24px;
          &_title {
            color: var(--Color-gray-600, #4d4d4d);
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            font-weight: 400;
          }
          &_titleresult {
            color: var(--Color-gray-600, #4d4d4d);
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            font-weight: 700;
          }
        }

        .premiumamount {
          display: flex;
          gap: 6.4rem;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          align-self: stretch;
          justify-content: space-around;
        }
      }
    }
    &_moreplan {
      color: #2cab93;
      display: flex;
      padding: 1.2rem 3.2rem;
      font-size: 1.6rem;
      margin-top: -2.2rem;
      align-items: flex-start;
      gap: 0.4rem;
      align-self: stretch;
      background: var(--Color-cyan-0, #eaf7f5);
    }
  }
}

.policyinfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6.4rem;
  align-self: stretch;
  align-items: center;
  justify-content: space-around;
  @include tablet {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
  div {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 0px auto;
    @include tablet {
      text-align: start;
      margin: 0;
    }
  }

  &_title {
    color: var(--Color-gray-600, #4d4d4d);
    text-align: center;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    @include tablet {
      text-align: start;
    }
  }
  &_titleresult {
    color: var(--Color-gray-600, #4d4d4d);
    text-align: center;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    line-height: 24px;
    font-weight: 700;
    @include tablet {
      text-align: start;
    }
  }
}
.companylogo {
  width: 24rem;
  height: auto;
  @include tablet {
    width: 20rem;
    
  }
}
