.bannerprotectcontainer {
  // margin: 0 auto;
  width: 100vw;

  .bannerprotect {
    width: 100vw;
    height: 40.6rem;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.43) 0%,
        rgba(0, 0, 0, 0.43) 100%
      ),
      url("https://utfs.io/f/c25029c9-2480-46db-a1ac-5393e1c4a0af-o5h67v.svg");
    background-repeat: no-repeat; /* Ensures the background image does not repeat */
    background-size: cover; /* This scales the background image to cover the entire element */
    background-position: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 3rem;
    // @include tablet {
    //   height: 30.6rem;
    //   gap: 1rem;
    // }

    &_content {
      // max-width: $max-width;
      // padding-top: 8.2rem;
      padding: 4rem;
      max-width: 75.5rem;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 5rem; /* 125% */
      @include tablet {
        font-size: 3.2rem;
        line-height: 4rem;
        width: 100%;
        padding: 4.2rem 2rem;
      }
    }
  }
}
